<template>
  <div class="d-flex flex-row justify-space-between mt-10">
    <span class="text-h5 text--secondary"> {{ label }}: </span>

    <span class="text-h5 font-weight-bold flex-shrink-0"> {{ amount }} {{ payload.currencySymbol }} </span>
  </div>
</template>

<script>
export default {
  name: 'SchemaFormTotalAmount',

  props: {
    label: { type: String, required: true },
    payload: { type: Object, required: true },
  },

  computed: {
    amount() {
      const result = this.payload.services.reduce((total, service) => {
        const amountWithVat = this.roundToTwoDecimalsBankers(service.amount + service.amount * (service.vat / 100));
        return total + Number(amountWithVat);
      }, 0);

      return Number.isNaN(result) ? 0 : result;
    },
  },

  methods: {
    roundToTwoDecimalsBankers(value) {
      // Умножаем на 100 и добавляем небольшую поправку для устранения погрешностей
      const scaledValue = Math.round((value * 100 + Number.EPSILON) * 1000) / 1000;

      // Выполняем банковское округление
      const integerPart = Math.floor(scaledValue); // Целая часть
      const fractionalPart = scaledValue - integerPart; // Дробная часть

      // Проверяем ситуацию "на середине" (0.5)
      if (fractionalPart === 0.5) {
        // Если на середине, округляем до ближайшего чётного
        return (integerPart % 2 === 0 ? integerPart : integerPart + 1) / 100;
      }

      // В остальных случаях применяем обычное округление
      return Math.round(scaledValue) / 100;
    },
  },
};
</script>
